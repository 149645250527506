import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import loadingBar from './loading.gif';

type Props = ReactModal.Props & {
    preventBackgroundScroll?: boolean | undefined;
};

const Loading: React.FC<Props> = ({ preventBackgroundScroll, ...props }) => {
    useEffect(() => {
        if (preventBackgroundScroll)
            document.body.style.overflow = props.isOpen ? 'hidden' : 'visible';
    }, [preventBackgroundScroll, props.isOpen]);

    return (
        <React.Fragment>
            {props.isOpen ? (
                <div className="gmt_loading_dim">
                    <img src={loadingBar} alt="로딩" className="gmt_loading" />
                </div>
            ) : (
                ''
            )}
        </React.Fragment>
    );
};

export default Loading;
