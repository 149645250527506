import Modal from 'common/modal';
import React from 'react';

interface PropsType {
    openErrormodal: boolean;
    setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
    errorMessage: string;
}

const GmtPaymentErrorModal: React.FC<PropsType> = ({
    openErrormodal,
    setOpenErrorModal,
    errorMessage
}) => {
    return (
        <React.Fragment>
            <Modal
                isOpen={openErrormodal}
                contentLabel="modal"
                className="popTyLayer2"
                overlayClassName="gmt_popup_dim"
                closeTimeoutMS={100}>
                <p className="gmt_popup_text">{errorMessage}</p>
                <div className="gmt_popup_button_error_box">
                    <button
                        type="button"
                        className="gmt_payment_button"
                        onClick={() => {
                            setOpenErrorModal(false);
                        }}>
                        OK
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default GmtPaymentErrorModal;
