import { confirmPaymentAPI } from 'gmtpay/api/GmtPayAPI';
import Modal from '../../common/modal';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from 'common/loading';
import { GMT_CONSTANT } from '../../constant/GmtConstant';
import { closeWebView } from 'gmtpay/interface/GmtPayAppInterface';
import { isAndroid, isIOS } from 'react-device-detect';

interface PropsType {
    setTransactionInfo: React.Dispatch<React.SetStateAction<TransactionInfo>>;
    setTransactionConfirm: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const GmtPaymentReady: React.FC<PropsType> = ({
    setTransactionInfo,
    setTransactionConfirm,
    setErrorMessage,
    setOpenErrorModal
}) => {
    const [onExit, setOnExit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const langCode = searchParams.get('lang') || 'en';
    const [osType, setOsType] = useState<string>('');
    const [downloadURL, setDownloadURL] = useState<string>('');
    const { t, i18n } = useTranslation();
    const { ranNo } = useParams<{ ranNo: string }>();
    const location = useLocation();
    const tranId = new URLSearchParams(location.search).get('tranId');
    const navigate = useNavigate();

    useEffect(() => {
        i18n.changeLanguage(langCode);
    }, [langCode, i18n]);

    useEffect(() => {
        if (isAndroid) {
            setOsType('Android');
            setDownloadURL(GMT_CONSTANT.GMONEY_ANDROID_APP_DOWNLOAD_URL_SCHEME);
        } else if (isIOS) {
            setOsType('iOS');
            setDownloadURL(GMT_CONSTANT.GMONEY_IOS_APP_DOWNLOAD_URL);
        }
    }, [ranNo]);

    const openGmoneyApp = useCallback((tranId: string) => {
        if (isIOS || isAndroid) {
            window.location.href = `${GMT_CONSTANT.GMONEY_DOMAIN}${GMT_CONSTANT.GMONEY_GMTPAY}${GMT_CONSTANT.THEPAY}?${GMT_CONSTANT.TRANID}${tranId}`;
        } else {
            window.location.href = GMT_CONSTANT.GMONEY_LANDING_PAGE_URL;
        }
    }, []);

    console.log('ranNo::: ', ranNo);
    console.log('tranId::: ', tranId);

    if (tranId != null) {
        // 다운로드 안내 페이지로 이동 (IOS만 사파리에 표시)
        navigate('/downloadGuide');
    }

    // next 클릭
    const onClickNext = () => {
        // 결제 유효성 검사 API 호출
        callConfirmPaymentAPI();
    };

    // install app 버튼 클릭
    const onClickInstall = () => {
        window.location.href = downloadURL;
    };

    // 결제 취소 버튼 클릭
    const onClickCancle = () => {
        closeWebView(setOpenErrorModal, setErrorMessage);
    };

    // 결제 유효성 검사 API
    const callConfirmPaymentAPI = useCallback(async () => {
        if (!ranNo) return;

        try {
            setLoading(true);
            const result = await confirmPaymentAPI(ranNo, osType);

            switch (result?.rsp_code) {
                case '0000':
                    openGmoneyApp(result?.tran_id);
                    setTransactionInfo(result);
                    setTransactionConfirm(true);

                    console.log(result?.tran_id);
                    break;
                default:
                    setOpenErrorModal(true);
                    setErrorMessage(t(result?.rsp_code, 'API FAIL'));
                    break;
            }
        } catch (error) {
            setOpenErrorModal(true);
            setErrorMessage(t('7108', 'Network Error'));
        } finally {
            setLoading(false);
        }
    }, [
        setErrorMessage,
        setOpenErrorModal,
        t,
        osType,
        ranNo,
        setTransactionConfirm,
        setTransactionInfo,
        openGmoneyApp
    ]);

    return (
        <React.Fragment>
            <div className="gmt_app_header gmt_ready">
                {/* 닫기 버튼 */}
                <img
                    className="gmt_close_ico"
                    src="/assets/images/close.png"
                    alt="닫기"
                    onClick={() => {
                        setOnExit(true);
                    }}
                />
                {/* 지머니트랜스 로고 */}
                <img
                    className="gmt_logo"
                    src="/assets/images/logo.png"
                    alt="로고"
                />
            </div>
            <div className="gmt_payment_ready_main">
                <p className="gmt_payment_text">
                    {t(
                        'gmtpay_guide01',
                        'Please press the button below to pay with GmoneyTrans App'
                    )}
                </p>
                <button className="gmt_payment_button" onClick={onClickNext}>
                    {t('bridge_button01', 'Next')}
                </button>
            </div>
            <button className="gmt_install_button" onClick={onClickInstall}>
                {t('bridge_button02', 'Install GmoneyTrans App')}
            </button>

            {/* 결제 취소 팝업 */}
            <Modal
                isOpen={onExit}
                contentLabel="modal"
                className="popTyLayer2"
                overlayClassName="gmt_popup_dim"
                closeTimeoutMS={100}>
                <p className="gmt_popup_title">
                    {t('gmtpay_guide02', 'Would you like to cancel the payment?')}
                </p>
                <div className="gmt_popup_button_box">
                    <button
                        type="button"
                        className="gmt_payment_button exit"
                        onClick={() => {
                            setOnExit(false);
                        }}>
                        {t('bridge_button03', 'No')}
                    </button>
                    <button
                        type="button"
                        className="gmt_payment_button"
                        onClick={onClickCancle}>
                        {t('gmtpay_button01', 'Cancel Payment')}
                    </button>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </React.Fragment>
    );
};

export default GmtPaymentReady;
