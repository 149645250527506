import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {copyToClipboard} from "../../../util/copyToClipboard";
import {GMT_CONSTANT} from "../../../constant/GmtConstant";
import {useLinkRootProvider} from "../LinkRoot/LinkRootProvider";

type ContextProvider = {
    shortUrlInfo: ShortUrlInfo;
    showToast: (message: string) => void
}

const InviteFriendBody: React.FC = () => {

    const { t } = useTranslation();
    const defaultMobileAppLink = GMT_CONSTANT.GMONEY_DEFAULT_DYNAMIC_LINK;

    const {
        shortUrlInfo,
        showToast
    }: ContextProvider = useLinkRootProvider();

    const onInviteCodeClick = () => {
        copyToClipboard(shortUrlInfo.inviteCode);

        showToast(t('invite_copy_toast', 'Code copied.'));
    };

    const openDynamicLink = useCallback(() => {
        if (shortUrlInfo.mobileAppLink !== null && shortUrlInfo.mobileAppLink !== "") {
            window.location.href = shortUrlInfo.mobileAppLink;
        } else {
            window.location.href = defaultMobileAppLink;
        }
    }, [shortUrlInfo.mobileAppLink, defaultMobileAppLink]);

    return (
        <React.Fragment>
            <div className="gmt_link_info_background">
                <img
                    className="gmt_link_header_logo"
                    src="/assets/images/logo_x2.png"
                    alt="로고"
                />
                <div className="gmt_link_content">
                    <img
                        className="gmt_link_coupon_image"
                        src="/assets/images/free_coupon.png"
                        alt="로고"
                    />
                    <p className="gmt_link_title">{t('invite_title01', '100% FREE Coupon From Your Friend!')}</p>
                    <p className="gmt_link_detail">{t('invite_guide01', 'All you have to do is install the app, sign up, and use it right away.')}</p>
                    {shortUrlInfo.senderName !== '' && shortUrlInfo.inviteCode !== '' &&
                        <div>
                            <div>
                                <p className="gmt_link_invite_info_title">{t('invite_sender', 'Sender')}</p>
                                <p className="gmt_link_invite_info">{shortUrlInfo.senderName}</p>
                            </div>
                            {shortUrlInfo.nationality !== '' &&
                                <div>
                                    <p className="gmt_link_invite_info_title">{t('invite_nationality', 'Nationality')}</p>
                                    <p className="gmt_link_invite_info">{shortUrlInfo.nationality}</p>
                                </div>
                            }
                            <div>
                                <p className="gmt_link_invite_info_title">{t('invite_code', 'Invite Code')}</p>
                                <button className="gmt_link_invite_info gmt_link_underline"
                                        onClick={onInviteCodeClick}>{shortUrlInfo.inviteCode}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="gmt_link_reason_content">
                <p className="gmt_link_title">{t('invite_reason_title01', '5 Reason To Use GmoneyTrans')}</p>
                <div className="gmt_link_reason_card">
                    <img
                        className="gmt_link_reason_image"
                        src="/assets/images/reason_1.png"
                        alt="The first reason for using GmoneyTrans"
                    />
                    <p className="gmt_link_reason_header">{t('invite_reason_header01', '5 Minutes Global Transfer')}</p>
                    <p className="gmt_link_reason_detail">{t('invite_reason_detail01', 'You can send money within 5 minutes worldwide.')}</p>
                </div>
                <div className="gmt_link_reason_card">
                    <img
                        className="gmt_link_reason_image"
                        src="/assets/images/reason_2.png"
                        alt="The second reason for using GmoneyTrans"
                    />
                    <p className="gmt_link_reason_header">{t('invite_reason_header02', 'Best Price')}</p>
                    <p className="gmt_link_reason_detail">{t('invite_reason_detail02', 'You can transfer money with the best price in the market.')}</p>
                </div>
                <div className="gmt_link_reason_card">
                    <img
                        className="gmt_link_reason_image"
                        src="/assets/images/reason_3.png"
                        alt="The third reason for using GmoneyTrans"
                    />
                    <p className="gmt_link_reason_header">{t('invite_reason_header03', 'Convenience')}</p>
                    <p className="gmt_link_reason_detail">{t('invite_reason_detail03', 'You can enjoy a convenient financial life in Korea, such as issuing debit cards, charging transportation cards, paying at convenience stores, and using ATMs with just one app.')}</p>
                </div>
                <div className="gmt_link_reason_card">
                    <img
                        className="gmt_link_reason_image"
                        src="/assets/images/reason_4.png"
                        alt="The fourth reason for using GmoneyTrans"
                    />
                    <p className="gmt_link_reason_header">{t('invite_reason_header04', 'Multilingual')}</p>
                    <p className="gmt_link_reason_detail">{t('invite_reason_detail04', 'No language barriers. Counselors of diverse nationalities are working to help you. If you have difficulty using the service, they will help you.')}</p>
                </div>
                <div className="gmt_link_reason_card">
                    <img
                        className="gmt_link_reason_image"
                        src="/assets/images/reason_5.png"
                        alt="The fifth reason for using GmoneyTrans"
                    />
                    <p className="gmt_link_reason_header">{t('invite_reason_header05', 'Trust')}</p>
                    <p className="gmt_link_reason_detail">{t('invite_reason_detail05', 'We offer safe and reliable global transfer services to more than 100 countries around the world.')}</p>
                </div>
                <div>
                    <img
                        className="gmt_link_point_image"
                        src="/assets/images/reward_image.png"
                        alt=""
                    />
                    <p className="gmt_link_title">{t('invite_benefit_title01', 'Invite your friends and get points')}</p>
                    <p className="gmt_link_point_detail">{t('invite_benefit_detail01', 'Points are given for every time you invite a friend. Any number of friends can be invited without restriction. We offer events that offer 10,000 points per friend and offer various prizes to the most invited member.')}</p>
                </div>
            </div>
            <button className="gmt_link_receive_coupon_button"
                    onClick={openDynamicLink}>{t('invite_receive_coupon_button', 'Receive 100% Coupon')}</button>
        </React.Fragment>
    );
}

export default InviteFriendBody;