import React from "react";
import {useTranslation} from "react-i18next";

const LinkRootMain: React.FC = () => {

    const {t} = useTranslation();

    return (
        <React.Fragment>
            <div className="gmt_app_content">
                <div className="gmt_app_header gmt_ready">
                    {/* 지머니트랜스 로고 */}
                    <img
                        className="gmt_link_not_found_logo"
                        src="/assets/images/logo.png"
                        alt="로고"
                    />
                </div>
                <div className="gmt_payment_ready_main">

                    <p className="gmt_error_title">
                        {t('GMT01', 'Page Not Found')}
                    </p>
                    <p className="gmt_error_content">
                        {t('GMT01', 'The requested page could not be found.')}
                        <br/>
                        <br/>
                        {t('GMT01', 'Please check the URL again.')}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default LinkRootMain;