import React, { useState } from 'react';
import GmtPaymentReady from './GmtPaymentReady';
import GmtPaymentConfirm from './GmtPaymentConfirm';
import GmtPaymentErrorModal from './GmtPaymentErrorModal';

const GmtPayment: React.FC = () => {
    const [transactionConfirm, setTransactionConfirm] =
        useState<boolean>(false);

    const [transactionInfo, setTransactionInfo] = useState({
        tran_id: '',
        tran_token: '',
        tran_status: '',
        partner_order_id: '',
        os_ver: ''
    });

    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    return (
        <React.Fragment>
            {transactionConfirm ? (
                <GmtPaymentConfirm
                    transactionInfo={transactionInfo}
                    setTransactionInfo={setTransactionInfo}
                    setOpenErrorModal={setOpenErrorModal}
                    setErrorMessage={setErrorMessage}
                />
            ) : (
                <GmtPaymentReady
                    setTransactionInfo={setTransactionInfo}
                    setTransactionConfirm={setTransactionConfirm}
                    setOpenErrorModal={setOpenErrorModal}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <GmtPaymentErrorModal
                openErrormodal={openErrorModal}
                setOpenErrorModal={setOpenErrorModal}
                errorMessage={errorMessage}
            />
        </React.Fragment>
    );
};

export default GmtPayment;
