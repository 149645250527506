export async function copyToClipboard(inviteCode?: string | null) {
    if (inviteCode === null || inviteCode === undefined || inviteCode === "") {
        return;
    }

    try {
        await navigator.clipboard.writeText(inviteCode);
    } catch (error) {
        copyInviteCodeByUsingCommend(inviteCode);
    }
}

function copyInviteCodeByUsingCommend(inviteCode: string) {
    const textArea = document.createElement('textarea');
    textArea.value = inviteCode;
    document.body.appendChild(textArea);
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Failed to copy text: ', err);
    } finally {
        document.body.removeChild(textArea);
    }
}