export const GMT_CONSTANT = {
    GMONEY_GMTPAY: "/gmtpay",

    GMONEY_DOMAIN: `${process.env.REACT_APP_SELF_APP_WAS}`,
    GMONEY_APP_URL_SCHEME: "gmtpay://",
    GMONEY_IOS_APP_DOWNLOAD_URL: "itms-apps://itunes.apple.com/app/1218137289",
    GMONEY_ANDROID_APP_DOWNLOAD_URL_SCHEME: "market://details?id=com.gmoneytrans",
    GMONEY_ANDROID_APP_DOWNLOAD_LINK: "https://play.google.com/store/apps/details?id=com.gmoneytrans&pcampaignid=web_share",
    GMONEY_LANDING_PAGE_URL: "https://gmoneytrans1.page.link/t8Jz",

    THEPAY: "/thepay",
    TRANID: "tranId=",

    GMONEY_FACEBOOK: "https://gmoneytrans.com/facebook_list/",
    GMONEY_YOUTUBE: "https://www.youtube.com/@Gmoneytrans",
    GMONEY_DEFAULT_DYNAMIC_LINK: "https://gmoneytrans1.page.link/t8Jz",

    GMONEY_CALL_NUMBER: "1670-4565"
}