import React, {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {isAndroid, isIOS} from "react-device-detect";
import {GMT_CONSTANT} from "../../constant/GmtConstant";
const GmtLinkQr: React.FC = () => {

    const location = useLocation();

    const [searchParams] = useSearchParams();
    const langCode = searchParams.get('lang') || 'en';
    const { t, i18n } = useTranslation();
    const [downloadLink, setDownloadLink] = useState<string>('');

    const onClickInstall = useCallback(() => {
        window.location.href = downloadLink;
    }, [downloadLink]);

    useEffect(() => {
        i18n.changeLanguage(langCode);
    }, [langCode, i18n]);

    useEffect(() => {
        if (isIOS) {
            setDownloadLink(GMT_CONSTANT.GMONEY_IOS_APP_DOWNLOAD_URL);
        } else if (isAndroid) {
            setDownloadLink(GMT_CONSTANT.GMONEY_ANDROID_APP_DOWNLOAD_URL_SCHEME);
        } else {
            setDownloadLink(GMT_CONSTANT.GMONEY_LANDING_PAGE_URL);
        }
    }, [location.pathname]);

    useEffect(() => {
        window.location.href = downloadLink;
    } ,[downloadLink]);

    return (
        <React.Fragment>
            <div className="gmt_app_content">
                <div className="gmt_app_header">
                    {/* 지머니트랜스 로고 */}
                    <img
                        className="gmt_logo"
                        src="/assets/images/logo.png"
                        alt="로고"
                    />
                </div>
                <div className="gmt_link_download_main">
                    <p className="gmt_link_text">
                        {t(
                            'bridge_dlGuide01',
                            'Please retry after GmoneyTrans App download.'
                        )}
                    </p>
                    <button className="gmt_payment_button" onClick={onClickInstall}>
                        {t('bridge_download', 'Download')}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default GmtLinkQr;