import React from "react";
import {GMT_CONSTANT} from "../../../constant/GmtConstant";

const InviteFriendFooter: React.FC = () => {

    const gmoneyCallNumber = GMT_CONSTANT.GMONEY_CALL_NUMBER;
    const facebookUrl = GMT_CONSTANT.GMONEY_FACEBOOK;
    const youtubeUrl = GMT_CONSTANT.GMONEY_YOUTUBE;

    const callGmoney = () => {
        window.location.href = `tel:${gmoneyCallNumber}`;
    }

    const onFacebookClick = () => {
        window.location.href = facebookUrl;
    };

    const onYoutubeClick = () => {
        window.location.href = youtubeUrl;
    };

    return (
        <React.Fragment>
            <div className="gmt_link_footer_content">
                <img
                    className="gmt_link_footer_logo"
                    src="/assets/images/logo_dark.png"
                    alt="흑백 로고"
                />
                <div>
                    <p className="gmt_link_footer_title gmt_link_footer_margin_top">Support</p>
                    <p className="gmt_link_footer_detail">10:00am to 10:00pm</p>
                </div>
                <div className="gmt_link_phone_no">
                    <img
                        src="/assets/images/phone.png"
                        alt="전화 아이콘"
                    />
                    <button className="gmt_link_phone_no_text" onClick={callGmoney}>{gmoneyCallNumber}</button>
                </div>
                <div>
                    <p className="gmt_link_footer_title gmt_link_footer_margin_top">Gmoneytrans Branch</p>
                    <p className="gmt_link_footer_detail">10:00am to 08:00pm</p>
                </div>
                <div>
                    <p className="gmt_link_footer_title gmt_link_footer_address_margin_top">Ansan</p>
                    <p className="gmt_link_footer_detail">2F, 17. Damunhwa-gil, Danwon-gu, Ansansi, Gyeonggi-do</p>
                    <br/>
                    <p className="gmt_link_footer_title">Gimhae</p>
                    <p className="gmt_link_footer_detail">104, 321, Bunseong-ro, Gimhae-si, Gyeongsangnam-do</p>
                    <br/>
                    <p className="gmt_link_footer_title">Dongdaemun</p>
                    <p className="gmt_link_footer_detail">305-1, Jong-ro, Jongno-gu, Seoul</p>
                </div>
                <div className="gmt_link_footer_social">
                    <button onClick={onFacebookClick}>
                        <img className="gmt_link_footer_button"
                            src="/assets/images/facebook.png"
                            alt="Facebook"
                        />
                    </button>
                    <button onClick={onYoutubeClick}>
                        <img className="gmt_link_footer_button"
                            src="/assets/images/youtube.png"
                            alt="Youtube"
                        />
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default InviteFriendFooter;
