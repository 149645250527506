import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {closeWebView} from "gmtpay/interface/GmtPayAppInterface";

const NetworkError: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const langCode = searchParams.get('lang') || 'en';

    useEffect(() => {
        i18n.changeLanguage(langCode);
    }, [langCode, i18n]);

    const closeWeb = () => {
        closeWebView();
    };

    return (
        <React.Fragment>
            <div className="gmt_app_content">
                <div className="gmt_app_header gmt_ready">
                    {/* 닫기 버튼 */}
                    <img
                        className="gmt_close_ico"
                        src="/assets/images/close.png"
                        alt="닫기"
                        onClick={() => {
                            closeWeb();
                        }}
                    />
                    {/* 지머니트랜스 로고 */}
                    <img
                        className="gmt_logo"
                        src="/assets/images/logo.png"
                        alt="로고"
                    />
                </div>
                <div className="gmt_payment_main">
                    <img
                        className="gmt_error_logo"
                        src="/assets/images/icon_error.png"
                        alt="경고"
                    />
                    <p className="gmt_error_title">
                        {t('GMT01', 'Network Error')}
                    </p>
                    <p className="gmt_error_content">
                        {t('GMT01', 'Unable to connect to the server.')}
                        <br />
                        <br />
                        {t(
                            'GMT01',
                            'Please check your network connection and try again.'
                        )}
                    </p>
                    <button
                        className="gmt_payment_button"
                        onClick={() => {
                            closeWeb();
                        }}>
                        {t('GMT01', 'OK')}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NetworkError;
