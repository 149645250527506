import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {isAndroid, isIOS} from "react-device-detect";
import {GMT_CONSTANT} from "../../constant/GmtConstant";

const GmtPaymentDownloadGuide: React.FC = () => {
    const [searchParams] = useSearchParams();
    const langCode = searchParams.get('lang') || 'en';
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(langCode);
    }, [langCode, i18n]);

    useEffect(() => {
        if (isIOS){
            window.location.href = GMT_CONSTANT.GMONEY_IOS_APP_DOWNLOAD_URL;
        } else if (isAndroid) {
            window.location.href = GMT_CONSTANT.GMONEY_ANDROID_APP_DOWNLOAD_URL_SCHEME;
        } else {
            window.location.href = GMT_CONSTANT.GMONEY_LANDING_PAGE_URL;
        }
    }, []);

    return (
        <React.Fragment>
            <div className="gmt_app_content">
                <div className="gmt_app_header">
                    {/* 지머니트랜스 로고 */}
                    <img
                        className="gmt_logo"
                        src="/assets/images/logo.png"
                        alt="로고"
                    />
                </div>
                <div className="gmt_payment_download_main">
                    <p className="gmt_payment_text">
                        {t('bridge_dlGuide01', 'Please retry after GmoneyTrans App download.')}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default GmtPaymentDownloadGuide;
