import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {getMappedLanguage} from "config/languageMapping";
import {findShortUrlInfo} from "../../api/GmtLinkAPI";
import {useLinkRootProvider} from "./LinkRootProvider";
import InviteFriend from "../InviteFriend";
import Toast from "../../component/Toast";
import {useNavigate} from "react-router-dom";

type ContextProvider = {
    shortUrlInfo: ShortUrlInfo;
    setShortUrlInfo: React.Dispatch<React.SetStateAction<ShortUrlInfo>>;
    toastMessage: string | null;
    setToastMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

const LinkRootMain: React.FC = () => {
    const { linkCode } = useParams<{ linkCode: string }>();
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState('');
    const navigate = useNavigate();

    const {
        shortUrlInfo,
        setShortUrlInfo,
        toastMessage,
        setToastMessage
    }: ContextProvider = useLinkRootProvider();

    const closeToast = () => {
        setToastMessage(null);
    };

    useEffect(() => {
        setLanguage(getMappedLanguage(navigator.language || (navigator as any).userLanguage));
        i18n.changeLanguage(language);

        if (linkCode !== undefined && linkCode !== "" && language !== "") {
            findShortUrlInfo(linkCode, language).then(result => {

                if (result?.code === "0000") {
                    setShortUrlInfo({
                        customerNo: result.response.customerNo,
                        shortUrlManageNo: result.response.shortUrlManageNo,
                        countryCode: result.response.countryCode,
                        linkCode: result.response.linkCode,
                        linkUrl: result.response.linkUrl,
                        createDate: result.response.createDate,
                        shortUrlType: result.response.shortUrlType,
                        reactNavigatePath: result.response.reactNavigatePath,
                        senderName: result.response.senderName,
                        nationality: result.response.nationality,
                        inviteCode: result.response.inviteCode,
                        mobileAppLink: result.response.mobileAppLink
                    });
                } else {
                    navigate("/link/notfound");
                }
            }).catch(() => {
                navigate("/link/notfound");
            });
        }
    }, [setShortUrlInfo, linkCode, language, i18n, navigate]);

    return (
        <React.Fragment>
            {shortUrlInfo.shortUrlType === "01" &&
                <InviteFriend/>
            }
            {toastMessage && <Toast message={toastMessage} onClose={closeToast}/>}
        </React.Fragment>
    );
}

export default LinkRootMain;