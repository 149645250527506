import { isAndroid, isIOS } from 'react-device-detect';
import React from 'react';

/**
 * 브릿지 페이지 닫기 인터페이스 요청 함수
 * 브릿지 페이지를 열고 있는 웹뷰에 닫기 interface 가 구현되어 있지 않은 경우 에러 모달을 띄운다.
 * 단, 두 파라미터가 세팅되어 있지 않은 경우 모달을 띄우지 않고 콘솔 로그를 출력한다.
 *
 * @param setOpenErrorModal 에러메시지 모달 표시 세팅
 * @param setErrorMessage
 */
export const closeWebView = (
    setOpenErrorModal: React.Dispatch<
        React.SetStateAction<boolean>
    > | null = null,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>> | null = null
) => {
    try {
        if (isIOS) {
            window.webkit?.messageHandlers.closeGmtPayBridge.postMessage(null);
        } else if (isAndroid) {
            window.appInterface.closeGmtPayBridge();
        } else {
            alert('Please try on the mobile device.');
        }
    } catch (e) {
        if (setOpenErrorModal !== null && setErrorMessage !== null) {
            setOpenErrorModal(true);
            setErrorMessage(
                'There was a problem processing the transaction. Please contact Customer Service.'
            );
        } else {
            console.log(
                'There was a problem processing the transaction. Please contact Customer Service.'
            );
        }
    }
};

/**
 * 결제확정api 수동 호출 테스트용 인터페이스 (Dev Only)
 * @param setOpenErrorModal
 * @param setErrorMessage
 * @param transactionInfo
 */
export const completePaymentInterface = (
    setOpenErrorModal: React.Dispatch<
        React.SetStateAction<boolean>
    > | null = null,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>> | null = null,
    transactionInfo: TransactionInfo
) => {
    try {
        if (isIOS) {
            window.webkit?.messageHandlers.completeGmtPayBridge.postMessage({
                partnerOrderId: transactionInfo.partner_order_id,
                tranId: transactionInfo.tran_id,
                tranToken: transactionInfo.tran_token
            });
        } else if (isAndroid) {
            window.appInterface.completeGmtPayBridge(
                transactionInfo.partner_order_id,
                transactionInfo.tran_id,
                transactionInfo.tran_token
            );
        } else {
            alert('Please try on the mobile device.');
        }
    } catch (e) {
        if (setOpenErrorModal !== null && setErrorMessage !== null) {
            setOpenErrorModal(true);
            setErrorMessage(
                'There was a problem processing the transaction. Please contact customer service at 1670-4565.'
            );
        } else {
            console.log(
                'There was a problem processing the transaction. Please contact customer service at 1670-4565.'
            );
        }
    }
};

declare global {
    export interface Window {
        appInterface: AppInterface;
        webkit?: any;
    }

    export interface AppInterface {
        closeGmtPayBridge: () => void;
        completeGmtPayBridge: (
            partnerOrderId: string,
            tranId: string,
            tranToken: string
        ) => void;
    }
}
