import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../src/config/i18n';
import Modal from 'react-modal';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

Modal.setAppElement('#root');

root.render(
    <Suspense fallback={<></>}>
        <App />
    </Suspense>
);
