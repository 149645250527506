import axios from 'axios';

const client = axios.create({
    baseURL: `${process.env.REACT_APP_CARD_APP_WAS}`,
    responseType: 'json',
    withCredentials: true
});

export async function findShortUrlInfo(linkCode: string, language: string) {
    return client.post('/api/event/gmtShortUrlInfo', {
        link_code: linkCode,
        language_code: language
    })
    .then((result) => result.data)
    .catch((error) => {
        console.error(error);
        throw error;
    });
}