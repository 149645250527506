import React from "react";
import LinkRootProvider from "./LinkRootProvider";
import LinkRootMain from "./LinkRootMain";

const LinkRoot: React.FC = () => {
    return (
        <LinkRootProvider>
            <LinkRootMain/>
        </LinkRootProvider>
    );
}

export default LinkRoot;