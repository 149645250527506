import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GmtPayment from './gmtpay/views';
import NotFound from 'gmtpay/views/errors/NotFound';
import NetworkError from 'gmtpay/views/errors/NetworkError';
import UndefinedError from 'gmtpay/views/errors/UndefinedError';
import ExpiredError from 'gmtpay/views/errors/ExpiredError';
import InvalidError from 'gmtpay/views/errors/InvalidError';
import GmtPaymentDownloadGuide from 'gmtpay/views/GmtPaymentDownloadGuide';
import GmtLinkQr from './gmtlink/views';
import LinkRoot from "./gmtlink/views/LinkRoot";
import LinkNotFound from "./gmtlink/views/NotFound/LinkNotFound";

const App: React.FC = () => {
    useEffect(() => {
        const updatePadding = () => {
            const viewportHeight = window.innerHeight;
            const contentElement = document.querySelector(
                '.gmt_app_content'
            ) as HTMLElement;
            if (contentElement) {
                contentElement.style.paddingBottom = `${
                    viewportHeight * 0.05
                }px`;
            }
        };

        updatePadding();
        window.addEventListener('resize', updatePadding);

        return () => {
            window.removeEventListener('resize', updatePadding);
        };
    }, []);

    return (
        <>
            <div className="gmt_app_content">
                <BrowserRouter>
                    <Routes>
                        <Route element={<GmtLinkQr />} path="/gmtlink/qr/*" />
                        <Route element={<LinkRoot />} path="/link/:linkCode" />
                        <Route element={<LinkNotFound />} path="/link/notfound" />
                        <Route element={<GmtPayment />} path="gmtpay/:ranNo" />
                        <Route element={<GmtPayment />} path="gmtpay/thepay" />
                        <Route element={<NetworkError />} path="/network" />
                        <Route element={<UndefinedError />} path="/undefined" />
                        <Route element={<ExpiredError />} path="/expired" />
                        <Route element={<InvalidError />} path="/invalid" />
                        <Route element={<GmtPaymentDownloadGuide />} path="/downloadGuide" />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </div>
        </>
    );
};

export default App;
