import axios from 'axios';

const client = axios.create({
    baseURL: `${process.env.REACT_APP_SELF_APP_WAS}`,
    responseType: 'json',
    withCredentials: true
});

/**
 * 결제유효성확인 API
 * @param ranNo
 * @param osType
 * @returns
 */
export async function confirmPaymentAPI(ranNo: string, osType: string) {
    return client
        .post(`/api/gmtpay/v1/payment/confirm`, {
            ran_num: ranNo,
            os_type: osType
        })
        .then((res) => res.data)
        .catch((error) => {
            console.log(error);
            throw error;
        });
}

/**
 * 결제준비결과확인 API
 * @param ranNo
 * @param transactionId
 * @returns
 */
export async function paymentReadyResultAPI(
    ranNo: string,
    transactionId: string
) {
    return client
        .post(`/api/gmtpay/v1/payment/ready/result`, {
            ran_num: ranNo,
            tran_id: transactionId
        })
        .then((res) => res.data)
        .catch((error) => {
            console.log(error);
            throw error;
        });
}
