import React, {useEffect} from 'react';
import ReactModal from 'react-modal';

type Props = ReactModal.Props & {
    preventBackgroundScroll?: boolean | undefined;
}

const Modal: React.FC<Props> = ({preventBackgroundScroll, ...props}) => {

    useEffect(() => {
        if (preventBackgroundScroll)
            document.body.style.overflow = props.isOpen ? 'hidden' : 'visible'
    }, [preventBackgroundScroll, props.isOpen]);

    return (
        <ReactModal {...props}>
            {props.children}
        </ReactModal>
    );
};

export default Modal;
