import React, {useState} from "react";

type Props = {
    children : React.ReactNode;
}

type Context = {
    shortUrlInfo: ShortUrlInfo;
    setShortUrlInfo: React.Dispatch<React.SetStateAction<ShortUrlInfo>>;
    showToast: (message: string) => void;
    toastMessage: string | null;
    setToastMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

const LinkRootContext = React.createContext<Context | undefined>(undefined);

const LinkRootProvider: React.FC<Props> = ({children}) => {
    const [shortUrlInfo, setShortUrlInfo] = useState<ShortUrlInfo>({
        customerNo: "",
        shortUrlManageNo: "",
        countryCode: "",
        linkCode: "",
        linkUrl: "",
        createDate: "",
        shortUrlType: "",
        reactNavigatePath: "",
        inviteCode: null,
        mobileAppLink: null,
        senderName: null,
        nationality: null
    });

    const [toastMessage, setToastMessage] = useState<string | null>(null);

    const showToast = (message: string) => {
        setToastMessage(message);
        setTimeout(() => {
            setToastMessage(null);
        }, 2000); // 2초 후에 토스트 메시지 사라짐
    };

    return (
        <LinkRootContext.Provider value={{
            shortUrlInfo, setShortUrlInfo, showToast, toastMessage, setToastMessage
        }}>
            {children}
        </LinkRootContext.Provider>
    );
}

const useLinkRootProvider = () => {
    const context = React.useContext<Context | undefined>(LinkRootContext);

    if (context === undefined) {
        throw new Error("useLinkRootProvider");
    }

    return context;
};

export {useLinkRootProvider};

export default LinkRootProvider;