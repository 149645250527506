export const languageMap = new Map<string, string>([
        ["en-US", "en"],
        ["ko-KR", "kr"],
        ["ne-NP", "np"],
        ["km-KH", "kh"],
        ["ru-RU", "ru"],
        ["uz-UZ", "uz"],
        ["id-ID", "id"],
        ["mn-MN", "mn"],
        ["bn-BD", "bd"],
        ["my-MM", "mm"],
        ["tl-PH", "ph"],
        ["si-LK", "lk"],
        ["th-TH", "th"],
        ["vi-VN", "vn"],
        ["fr-FR", "fr"],
        ["kk-KZ", "kz"],
        ["tr-TR", "tr"],
        ["zh-CN", "cn"]
]);

export function getMappedLanguage(browserLanguage: string): string {
    return languageMap.get(browserLanguage) || 'en';
}