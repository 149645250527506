import React, { useEffect } from 'react';

interface ToastProps {
    message: string | null;
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, onClose }) => {

    useEffect(() => {
        const removeTimer = setTimeout(() => {
            onClose();
        }, 5000);

        return () => {
            clearTimeout(removeTimer);
        };
    }, [onClose]);

    return (
        <>
            {message &&
                <div style={{...toastStyle}}>
                    {message}
                </div>
            }
        </>
    );
};

const toastStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '70px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#000',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    zIndex: 1000,
    opacity: 0.85
};

export default Toast;
