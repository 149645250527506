/* eslint-disable */
import Loading from 'common/loading';
import Modal from 'common/modal';
import { paymentReadyResultAPI } from 'gmtpay/api/GmtPayAPI';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    closeWebView,
    completePaymentInterface
} from 'gmtpay/interface/GmtPayAppInterface';
import { useParams, useSearchParams } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';
import { GMT_CONSTANT } from '../../constant/GmtConstant';

interface PropsType {
    transactionInfo: TransactionInfo;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTransactionInfo: React.Dispatch<React.SetStateAction<TransactionInfo>>;
}

const GmtPaymentConfirm: React.FC<PropsType> = ({
    transactionInfo,
    setErrorMessage,
    setOpenErrorModal,
    setTransactionInfo
}) => {
    const [onExit, setOnExit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [downloadURL, setDownloadURL] = useState<string>('');
    const langCode = searchParams.get('lang') || 'en';
    const { t, i18n } = useTranslation();
    const { ranNo } = useParams<{ ranNo: string }>();
    const [openTestModal, setOpenTestModal] = useState<boolean>(false);

    useEffect(() => {
        i18n.changeLanguage(langCode);
    }, [langCode, i18n]);

    useEffect(() => {
        if (isAndroid) {
            setDownloadURL(GMT_CONSTANT.GMONEY_ANDROID_APP_DOWNLOAD_URL_SCHEME);
        } else if (isIOS) {
            setDownloadURL(GMT_CONSTANT.GMONEY_IOS_APP_DOWNLOAD_URL);
        }
    }, [ranNo]);

    // done 클릭
    const onClickDone = () => {
        // 결제준비 결과 확인 API 호출
        callPaymentReadyResultAPI();
    };

    // 결제 취소 버튼 클릭
    const onClickCancel = () => {
        closeWebView(setOpenErrorModal, setErrorMessage);
    };

    // install app 버튼 클릭
    const onClickInstall = () => {
        window.location.href = downloadURL;
    };

    // 결제확정 인터페이스 호출 (Dev Only)
    const onClickCompleteTest = () => {
        completePaymentInterface(
            setOpenErrorModal,
            setErrorMessage,
            transactionInfo
        );
        closeWebView(setOpenErrorModal, setErrorMessage);
    };

    // 결제준비 결과 확인 API
    const callPaymentReadyResultAPI = useCallback(async () => {
        if (!ranNo || !transactionInfo.tran_id) return;

        try {
            setLoading(true);
            const result = await paymentReadyResultAPI(
                ranNo,
                transactionInfo?.tran_id
            );
            switch (result?.rsp_code) {
                case '0000':
                    setTransactionInfo(result);
                    if (result?.partner_order_id.includes("gmt_test")) {
                        setOpenTestModal(true);
                    }
                    if (result?.redirect_url) {
                        const form = document.createElement('form');
                        form.method = 'POST';
                        form.action = result.redirect_url;
                        const fields = ['partner_id', 'partner_order_id', 'tran_id', 'tran_token'];
                        fields.forEach(field => {
                            if (result[field]) {
                                const input = document.createElement('input');
                                input.type = 'hidden';
                                input.name = field;
                                input.value = result[field];
                                form.appendChild(input);
                            }
                        });
                        console.log(form);
                        document.body.appendChild(form);
                        form.submit();
                    }
                    break;
                default:
                    setOpenErrorModal(true);
                    setErrorMessage(t(result?.rsp_code, 'API FAIL'));
                    break;
            }
        } catch (error) {
            setOpenErrorModal(true);
            setErrorMessage(t('7108', 'Network Error'));
        } finally {
            setLoading(false);
        }
    }, [ranNo, transactionInfo]);

    return (
        <React.Fragment>
            <div className="gmt_app_header">
                {/* 지머니트랜스 로고 */}
                <img
                    className="gmt_logo"
                    src="/assets/images/logo.png"
                    alt="로고"
                />
            </div>
            <div className="gmt_payment_confirm_main">
                <p className="gmt_payment_confirm_text">
                    {t(
                        'gmtpay_guide03',
                        'Please make the payment in GmoneyTrans App, then press the button below'
                    )}
                </p>
                {isAndroid && (
                    <div>
                        <button
                            className="gmt_install_button"
                            onClick={onClickInstall}>
                            {t(
                                'gmtpay_dlGuide01',
                                'If payment not successful, Please download GmoneyTrans App and try again.'
                            )}
                        </button>
                    </div>
                )}
            </div>
            <div className="gmt_payment_done">
                <button className="gmt_payment_button" onClick={onClickDone}>
                    {t('gmtpay_button02', 'Done')}
                </button>
            </div>
            {/* 결제 취소 팝업 */}
            <Modal
                isOpen={onExit}
                contentLabel="modal"
                className="popTyLayer2"
                overlayClassName="gmt_popup_dim"
                closeTimeoutMS={100}>
                <p className="gmt_popup_title">
                    {t('gmtpay_guide02', 'Would you like to cancel the payment?')}
                </p>
                <div className="gmt_popup_button_box">
                    <button
                        type="button"
                        className="gmt_payment_button exit"
                        onClick={() => {
                            setOnExit(false);
                        }}>
                        {t('bridge_button03', 'No')}
                    </button>
                    <button
                        type="button"
                        className="gmt_payment_button"
                        onClick={onClickCancel}>
                        {t('gmtpay_button01', 'Cancel Payment')}
                    </button>
                </div>
            </Modal>
            {/* 결제 확정 테스트 팝업 (Dev Only)*/}
            <Modal
                isOpen={openTestModal}
                contentLabel="modal"
                className="popTyLayer2"
                overlayClassName="gmt_popup_dim"
                closeTimeoutMS={100}>
                <p className="gmt_popup_title">결제완료 (결제확정api용)</p>
                <div className="gmt_popup_button_box">
                    <button
                        type="button"
                        className="gmt_payment_button"
                        onClick={onClickCompleteTest}>
                        OK
                    </button>
                    <button
                        type="button"
                        className="gmt_payment_button exit"
                        onClick={() => {
                            setOpenTestModal(false);
                        }}>
                        No
                    </button>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </React.Fragment>
    );
};

export default GmtPaymentConfirm;
