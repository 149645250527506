import React from "react";
import InviteFriendBody from "./InviteFriendBody";
import InviteFriendFooter from "./InviteFriendFooter";

const InviteFriend: React.FC = () => {

    return (
        <div className="gmt_link_background">
            <InviteFriendBody/>
            <InviteFriendFooter/>
        </div>
    );
};

export default InviteFriend;